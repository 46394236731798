<div class="container-fluid" style="padding-left: 0px !important; padding-right: 0px !important; margin-top: -15px;">
  <div *ngIf="message" style="height: 60px; padding: 20px; text-align: center;"><h4>{{message}}</h4></div>
  <div class="row row-nomargin">

    <div class="col-12" style="padding-left: 0; padding-right: 0;">
      <div style="padding: 30px 30px 40px 60px; position: absolute; max-width: 550px; color: white;">
        <hr style="width: 50px; border-top: 5px solid white;">
        <h2 style="line-height: 1.3; margin-top: 20px; font-size: 20pt; color: white; font-weight: 500 !important;">
          Australia's independent knowledge hub informing environmental stewardship decisions for primary producers
        </h2>
      </div>
      <img src="assets/images/banner.jpg" style="width: 100%; height: 500px; object-fit: cover;">
    </div>

  </div>
  <casi-search-australia-link></casi-search-australia-link>

  <div class="row row-nomargin">

    <div class="col-12 col-lg-6" style="padding: 0;">
      <img src="assets/images/b1b.png" style="width: 100%; height: 560px; object-fit: cover;">
    </div>
    <div class="col-12 col-lg-6" style="padding: 0px; position: relative;">
      <img src="assets/images/b2.png" style="width: 100%; height: 560px; object-fit: cover;">
      <div class="d-none d-md-block" style="padding: 90px 20px 20px 80px; position: absolute; top: 0;">
        <hr style="width: 50px; border-top: 5px solid #dd7f45;">
        <h2 style="line-height: 1.3; max-width: 480px; color: white; font-weight: 500 !important;">
          Highlighting benefits, risks
          and costs for productivity,
          environment, finance, supply
          chain and society, in fishing
          aquaculture, forestry, cropping,
          horticulture and livestock

        </h2>
      </div>
      <div class="d-block d-md-none" style="padding: 30px 60px; position: absolute; top: 0;">
        <hr style="width: 50px; border-top: 5px solid #dd7f45;">
        <h2 style="line-height: 1.3; color: white; font-weight: 500 !important; font-size: 20pt !important;">
          Highlighting benefits, risks
          and costs for productivity,
          environment, finance, supply
          chain and society, in fishing
          aquaculture, forestry, cropping,
          horticulture and livestock


        </h2>
      </div>

    </div>

  </div>


  <casi-icon-block></casi-icon-block>

  <!-- <div style="text-align: center; padding: 160px 20px 110px 20px;">
    <h2 style="font-weight: 500 !important;">Relevant for all primary industries</h2>
    <hr style="width: 50px; border-top: 5px solid #dd7f45; margin: 40px auto;">

    <div class="d-none d-lg-block" style="max-width: 1250px; margin: auto;">
      <div class="row row-nomargin" style="margin-top: 60px; margin-bottom: 60px;">
        <div class="col-lg-3" style="text-align: center; border-right: 1px solid #cbc8c8;">
          <img src="assets/images/forestry.png" style="margin-bottom: 20px; width: 100%; padding: 0px 40px 10px 40px;">
          <h2 style="font-weight: 500 !important;">Forestry</h2>
        </div>
        <div class="col-lg-3" style="text-align: center; border-right: 1px solid  #cbc8c8;">
          <img src="assets/images/fishers.png" style="margin-bottom: 20px; width: 100%; padding: 0px 40px 10px 40px;">
          <h2 style="font-weight: 500 !important;">Fishers</h2>
        </div>
        <div class="col-lg-3" style="text-align: center; border-right: 1px solid  #cbc8c8;">
          <img src="assets/images/farming.png" style="margin-bottom: 20px; width: 100%; padding: 0px 40px 10px 40px;">
          <h2 style="font-weight: 500 !important;">Livestock</h2>
        </div>
        <div class="col-lg-3" style="text-align: center;">
          <img src="assets/images/cropping.png" style="margin-bottom: 20px; width: 100%; padding: 0px 40px 10px 40px;">
          <h2 style="font-weight: 500 !important;">Cropping</h2>
        </div>
      </div>
    </div>

    <div class="d-block d-lg-none">
      <div class="row row-nomargin" style="margin-top: 20px; margin-bottom: 20px;">
        <div class="col-12 col-md-6" style="text-align: center;">
          <img src="assets/images/forestry.png" style="margin-bottom: 20px; max-width: 50%;">
          <h2 style="font-weight: 500 !important;">Forestry</h2>
          <br>
        </div>
        <div class="col-12 col-md-6" style="text-align: center;">
          <img src="assets/images/fishers.png" style="margin-bottom: 20px; max-width: 50%;">
          <h2 style="font-weight: 500 !important;">Fishers</h2>
          <br>
        </div>
        <div class="col-12 col-md-6" style="text-align: center;">
          <img src="assets/images/farming.png" style="margin-bottom: 20px; max-width: 50%;">
          <h2 style="font-weight: 500 !important;">Livestock</h2>
          <br>
        </div>
        <div class="col-12 col-md-6" style="text-align: center;">
          <img src="assets/images/cropping.png" style="margin-bottom: 20px; max-width: 50%;">
          <h2 style="font-weight: 500 !important;">Cropping</h2>
          <br>
        </div>
      </div>
    </div>


  </div> -->


  <div class="row row-nomargin">

    <div class="col-12 col-lg-6" style="padding: 0; position: relative;">
      <img src="assets/images/b3.png" style="width: 100%; height: 560px; object-fit: cover;">
      <div class="d-none d-lg-block" style="padding: 0px 20px 0px 80px; position: absolute; top: 0; margin-top: 5vw;">
        <hr style="width: 50px; border-top: 5px solid #dd7f45;">
        <h2 style="line-height: 1.3; max-width: 60%; color: white; font-weight: 500 !important;">
          Combining opportunities,
          resources, practices,
          benefits and risks in an
          easily accessible format

        </h2>
        <!-- <p class="largep" style="color: white;">

        </p> -->
      </div>
      <div class="d-block d-lg-none" style="padding: 30px 60px; position: absolute; top: 0; ">
        <hr style="width: 50px; border-top: 5px solid #dd7f45;">
        <h2 style="line-height: 1.3; color: white; font-weight: 500 !important;">
          Combining opportunities,
          resources, practices,
          benefits and risks in an
          easily accessible format

        </h2>
        <!-- <p class="largep" style="color: white;">
          Combining opportunities,
          resources, practices,
          benefits and risks in an
          easily accessible format.
        </p> -->
      </div>


    </div>
    <div class="col-12 col-lg-6" style="padding: 0;">
      <img src="assets/images/b4b.png" style="width: 100%; height: 560px; object-fit: cover;">
    </div>

  </div>

  <casi-discover-opportunity></casi-discover-opportunity>

  <div class="row row-nomargin d-block d-lg-none" style="padding-bottom: 40px;">
    <div class="col-12" style="padding: 40px 25px;">
      <h2 style="font-weight: 500 !important; margin-bottom: 40px;">Environer Explained</h2>
      
        <iframe style="height: 75vh; width: 100%; max-height: 360px;" src="https://www.youtube.com/embed/QBToTK1Ct9A" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      
      
    </div>
  </div>

  <div class="row row-nomargin d-none d-lg-block" style="padding: 0px 60px 60px 60px;">
    <div class="col-12" style="padding: 0px 25px 40px 25px;">
      <h2 style="font-weight: 500 !important; margin-bottom: 50px;">Environer Explained</h2>
      <div style="text-align: center; margin: auto;">
        <iframe style="height: 75vh; width: 100%; max-width: 1280px; max-height: 720px;" src="https://www.youtube.com/embed/QBToTK1Ct9A" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      
    </div>    
  </div>
   

  <div class="row row-nomargin">

    <div class="col-12 col-lg-6" style="padding: 0;">
      <img src="assets/images/b5.png" style="width: 100%; height: 560px; object-fit: cover;">
    </div>
    <div class="col-12 col-lg-6" style="padding: 0px; position: relative;">
      <img src="assets/images/b5b.png" style="width: 100%; height: 560px; object-fit: cover;">
      <div class="d-none d-md-block" style="padding: 100px 20px 20px 80px; position: absolute; top: 0;">
        <hr style="width: 50px; border-top: 5px solid #dd7f45;">
        <h2 style="line-height: 1.3; color: white; font-weight: 500 !important; max-width: 480px;">
          Enable primary producers to
          better understand and engage
          in market development and
          global trade opportunities

        </h2>
      </div>
      <div class="d-block d-md-none" style="padding: 50px 20px 20px 60px; position: absolute; top: 0;">
        <hr style="width: 50px; border-top: 5px solid #dd7f45;">
        <h2 style="line-height: 1.3; color: white; font-weight: 500 !important;">
          Enable primary producers to
          better understand and engage
          in market development and
          global trade opportunities


        </h2>
      </div>

    </div>

  </div>


  <div style="padding: 100px 8%; background-color: #efefef;">
    <hr style="width: 50px; border-top: 5px solid #dd7f45; margin: 0 auto 30px auto;">
    <div class="row row-nomargin ">

      <div class="col-12" style="padding: 40px;">

        <p style="font-size: 15pt !important; line-height: 1.3;">
          Environer brings together information about access to natural capital, biodiversity and carbon markets
          and financial products into one central hub. It provides clarity and access to independent information
          needed by Australia’s primary producers about relevant schemes. It allows producers and their advisers
          to compare benefits and obligations, support informed decision making, and make best use of opportunities
          in environmental stewardship and sustainability.
        </p>
      </div>
      <!-- <div class="col-12 col-md-6" style="padding: 40px;">

        <p style="font-size: 15pt !important; line-height: 1.3;">The key outcome is to bring together the mostly
          fragmented existing resources and information
          into one central hub. This will provide clarity
          and access to information needed by Australian
          producers about relevant schemes. It will allow
          them to compare benefits and obligations,
          support informed decision-making, and make
          best use of opportunities in environmental
          stewardship and sustainability.</p>
      </div> -->
    </div>
  </div>

  <div class="homeblock" style="padding-bottom: 80px;">

    <div class="d-none d-lg-block">
      <div class="row row-nomargin" style="font-size: medium;">
        <div class="col-lg-4" style="padding: 20px 50px; border-left: 1px solid;">

          <img src="assets/images/UNE_Logo.png" class="flogo">
          <h5 style="margin-top: 20px; font-size: 12pt; font-weight: bold;">Research by:</h5>

          <address>
            University of New England<br>
            Armidale NSW 2351 Australia</address>

          <a href="https://une.edu.au" target="_blank" style="font-weight: bold; text-decoration: none;">une.edu.au</a>
        </div>

        <div class="col-lg-4" style="padding: 20px 50px; border-left: 1px solid;">

          <img src="assets/images/agrifutures_logo.png" class="flogo" style="max-height: 100px; margin-top: -20px;">
          <h5 style="margin-top: 20px; font-size: 12pt; font-weight: bold;">Funded by:</h5>

          <address>
            AgriFutures Australia<br>
            Locked Bag 588<br>
            Wagga Wagga NSW 2650 Australia</address>

          <a href="https://agrifutures.com.au" target="_blank"
            style="font-weight: bold; text-decoration: none;">agrifutures.com.au</a>
        </div>

        <div class="col-lg-4" style="padding: 20px 50px; border-left: 1px solid;">
          <img src="assets/images/UNE_CASI_Logo.png" class="flogo">
          <h5 style="margin-top: 20px; font-size: 12pt; font-weight: bold;">Dashboard developed by:</h5>

          <address>UNE CASI (Computation, Analytics, Software, Informatics)<br>
            University of New England<br>
            Armidale NSW 2351 Australia</address>

          <a href="https://casi.une.edu.au" target="_blank"
            style="font-weight: bold; text-decoration: none;">casi.une.edu.au</a>
        </div>


      </div>
    </div>
    <div class="d-block d-lg-none">
      <div class="row row-nomargin" style="font-size: medium;">
        <div class="col-12" style="padding: 20px;">

          <img src="assets/images/UNE_Logo.png" class="flogo">
          <h5 style="margin-top: 20px; font-size: 12pt; font-weight: bold;">Research by:</h5>

          <address>
            University of New England<br>
            Armidale NSW 2351 Australia</address>

          <a href="https://une.edu.au" target="_blank" style="font-weight: bold; text-decoration: none;">une.edu.au</a>
        </div>

        <div class="col-12" style="padding: 20px;">

          <img src="assets/images/agrifutures_logo.png" class="flogo">
          <h5 style="margin-top: 20px; font-size: 12pt; font-weight: bold;">Funded by:</h5>

          <address>
            AgriFutures Australia<br>
            Locked Bag 588<br>
            Wagga Wagga NSW 2650 Australia</address>

          <a href="https://agrifutures.com.au" target="_blank"
            style="font-weight: bold; text-decoration: none;">agrifutures.com.au</a>
        </div>

        <div class="col-12" style="padding: 20px 20px 60px 20px;">
          <img src="assets/images/UNE_CASI_Logo.png" class="flogo">
          <h5 style="margin-top: 20px; font-size: 12pt; font-weight: bold;">Dashboard developed by:</h5>

          <address>UNE CASI (Computation, Analytics, Software, Informatics)<br>
            University of New England<br>
            Armidale NSW 2351 Australia</address>

          <a href="https://casi.une.edu.au" target="_blank"
            style="font-weight: bold; text-decoration: none;">casi.une.edu.au</a>
        </div>


      </div>
    </div>

  </div>


</div>
