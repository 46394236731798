<div class="d-none d-lg-block" style="text-align: center; padding: 100px 60px 20px 60px;">

  <h2 style="font-weight: 500 !important;">Discover Opportunities</h2>
  <hr style="width: 50px; border-top: 5px solid #dd7f45; margin: 40px auto 0 auto;">
  <!-- <img src="assets/images/chartlg.png" style="width: 100%;"> -->
</div>
<div class="homeblock-sm d-block d-lg-none" style="text-align: center; padding: 60px 20px;">
  <h2>Discover Opportunities</h2>
  <hr style="width: 50px; border-top: 5px solid #dd7f45; margin: 40px auto 0 auto;">
  <!-- <img src="assets/images/chart.png" style="width: 100%;"> -->
</div>


<div class="d-none d-lg-block">
  <div class="row row-nomargin" style="padding: 60px 60px 60px 60px;">
    <div class="col-lg-6"
      style="border-right: 1px solid #cbc8c8; border-bottom: 1px solid #cbc8c8; padding: 0 25px 40px 25px;">
      <img src="assets/images/outcomes.png" style="max-width: 160px; cursor: pointer"
      (click)="discoverOpportunity('opportunity_goal_type', null)"
      casiClickLogger="img[discoverOpportunity:opportunity_goal_type:null]">
      <h2 class="h2s" style="font-weight: 500 !important;">
        <a style="cursor: pointer; margin-right: 10px;" (click)="discoverOpportunity('opportunity_goal_type', null)"
        casiClickLogger="img[discoverOpportunity:opportunity_goal_type:null]">Project Goals</a> <casi-help-popover [infoId]="10"
          [iconStyle]="'color: #5d654d;'" style="display: inline-block;"></casi-help-popover></h2>

      <table>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/carbon.png"
              style="max-width: 70px; min-width: 45px;cursor: pointer"
              (click)="discoverOpportunity('opportunity_goal_type', 1)"
              casiClickLogger="img[discoverOpportunity:opportunity_goal_type:1]"></td>
          <td style="border-bottom: 1px solid #cbc8c8; width: 86%;">
            <a style="cursor: pointer" (click)="discoverOpportunity('opportunity_goal_type', 1)"
            casiClickLogger="img[discoverOpportunity:opportunity_goal_type:1]">Carbon and Emissions Reduction</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/biodiversity.png"
              style="max-width: 70px; min-width: 45px;cursor: pointer"
              (click)="discoverOpportunity('opportunity_goal_type', 2)"
              casiClickLogger="img[discoverOpportunity:opportunity_goal_type:2]"></td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer" (click)="discoverOpportunity('opportunity_goal_type', 2)"
            casiClickLogger="img[discoverOpportunity:opportunity_goal_type:2]">Natural Capital, Biodiversity and Ecosystem Services</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/sustainability.png"
              style="max-width: 70px; min-width: 45px;cursor: pointer"
              (click)="discoverOpportunity('opportunity_goal_type', 3)"
              casiClickLogger="img[discoverOpportunity:opportunity_goal_type:3]"></td>
          <td><a style="cursor: pointer" (click)="discoverOpportunity('opportunity_goal_type', 3)"
            casiClickLogger="img[discoverOpportunity:opportunity_goal_type:3]">Sustainability</a>
          </td>
        </tr>
      </table>
    </div>

    <div class="col-lg-6" style="border-bottom: 1px solid #cbc8c8; padding: 0 25px 40px 60px;">
      <img src="assets/images/opportunities.png" style="max-width: 160px; cursor: pointer"
      (click)="discoverOpportunity('classification_type', null)"
      casiClickLogger="img[discoverOpportunity:classification_type:null]">
      <h2 class="h2s" style="font-weight: 500 !important;">
        <a style="cursor: pointer; margin-right: 10px;" (click)="discoverOpportunity('classification_type', null)"
        casiClickLogger="img[discoverOpportunity:classification_type:null]">Market Opportunities</a> <casi-help-popover [infoId]="11"
          [iconStyle]="'color: #7ba89e;'" style="display: inline-block;"></casi-help-popover></h2>

      <table>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/income.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer"
              (click)="discoverOpportunity('classification_type', 1)"
              casiClickLogger="img[discoverOpportunity:classification_type:1]"></td>
          <td style="border-bottom: 1px solid #cbc8c8; width: 86%;">
            <a style="cursor: pointer" (click)="discoverOpportunity('classification_type', 1)"
            casiClickLogger="img[discoverOpportunity:classification_type:1]">Financial Outcome</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/market.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer"
              (click)="discoverOpportunity('classification_type', 2)"
              casiClickLogger="img[discoverOpportunity:classification_type:2]"></td>
          <td>
            <a style="cursor: pointer" (click)="discoverOpportunity('classification_type', 2)"
            casiClickLogger="img[discoverOpportunity:classification_type:2]">Market Access</a>
          </td>
        </tr>
      </table>
    </div>

    <div class="col-lg-6" style="border-right: 1px solid #cbc8c8; padding: 40px 25px;">
      <img src="assets/images/benefits.png" style="max-width: 160px; cursor: pointer"
      (click)="discoverOpportunity('co_benefit_class', null)"
      casiClickLogger="img[discoverOpportunity:co_benefit_class:null]">
      <h2 class="h2s" style="font-weight: 500 !important;">
        <a style="cursor: pointer; margin-right: 10px;" (click)="discoverOpportunity('co_benefit_class', null)"
        casiClickLogger="img[discoverOpportunity:co_benefit_class:null]">
        Project Outcomes</a> <casi-help-popover [infoId]="12"
          [iconStyle]="'color: #dd7f45;'" style="display: inline-block;"></casi-help-popover></h2>


      <table>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/production.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer"
              (click)="discoverOpportunity('co_benefit_class', 3)"
              casiClickLogger="img[discoverOpportunity:co_benefit_class:3]"></td>
          <td style="border-bottom: 1px solid #cbc8c8; width: 86%;">
            <a style="cursor: pointer" (click)="discoverOpportunity('co_benefit_class', 3)"
            casiClickLogger="img[discoverOpportunity:co_benefit_class:3]">Production & Productivity</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/environment.png"
              style="max-width: 70px; min-width: 45px;cursor: pointer"
              (click)="discoverOpportunity('co_benefit_class', 1)"
              casiClickLogger="img[discoverOpportunity:co_benefit_class:1]"></td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer" (click)="discoverOpportunity('co_benefit_class', 1)"
            casiClickLogger="img[discoverOpportunity:co_benefit_class:1]">Environment</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/financial.png"
              style="max-width: 70px; min-width: 45px;cursor: pointer"
              (click)="discoverOpportunity('co_benefit_class', 2)"
              casiClickLogger="img[discoverOpportunity:co_benefit_class:2]"></td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer" (click)="discoverOpportunity('co_benefit_class', 2)"
            casiClickLogger="img[discoverOpportunity:co_benefit_class:2]">Financial</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/supply.png"
              style="max-width: 70px; min-width: 45px;cursor: pointer"
              (click)="discoverOpportunity('co_benefit_class', 5)"
              casiClickLogger="img[discoverOpportunity:co_benefit_class:5]"></td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer" (click)="discoverOpportunity('co_benefit_class', 5)"
            casiClickLogger="img[discoverOpportunity:co_benefit_class:5]">Supply Chain</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/social.png"
              style="max-width: 70px; min-width: 45px;cursor: pointer"
              (click)="discoverOpportunity('co_benefit_class', 4)"
              casiClickLogger="img[discoverOpportunity:co_benefit_class:4]"></td>
          <td><a style="cursor: pointer" (click)="discoverOpportunity('co_benefit_class', 4)"
            casiClickLogger="img[discoverOpportunity:co_benefit_class:4]">Social</a>
          </td>
        </tr>
      </table>
    </div>
    <div class="col-lg-6" style="padding: 40px 25px 40px 60px;">
      <img src="assets/images/industries.png" style="max-width: 160px; cursor: pointer;"
      (click)="discoverOpportunity('industry', null)"
      casiClickLogger="img[discoverOpportunity:industry:null]">
      <h2 class="h2s" style="font-weight: 500 !important;">
        <a style="cursor: pointer; margin-right: 10px;" (click)="discoverOpportunity('industry', null)"
        casiClickLogger="img[discoverOpportunity:industry:null]">Industries</a> <casi-help-popover [infoId]="13"
          [iconStyle]="'color: #d9b35b;'" style="display: inline-block;"></casi-help-popover></h2>


      <table>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/forestry-n.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer" (click)="discoverOpportunity('industry', 2)"
              casiClickLogger="img[discoverOpportunity:industry:2]">
          </td>
          <td style="border-bottom: 1px solid #cbc8c8; width: 86%;">
            <a style="cursor: pointer" (click)="discoverOpportunity('industry', 2)"
            casiClickLogger="img[discoverOpportunity:industry:2]">Forestry Native</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/forestry2.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer" (click)="discoverOpportunity('industry', 1)"
              casiClickLogger="img[discoverOpportunity:industry:1]">
          </td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer" (click)="discoverOpportunity('industry', 1)"
            casiClickLogger="img[discoverOpportunity:industry:1]">Forestry Plantation</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/fisheries-w.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer" (click)="discoverOpportunity('industry', 4)"
              casiClickLogger="img[discoverOpportunity:industry:4]">
          </td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer" (click)="discoverOpportunity('industry', 4)"
            casiClickLogger="img[discoverOpportunity:industry:4]">Fishing</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/fisheries-i.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer" (click)="discoverOpportunity('industry', 3)"
              casiClickLogger="img[discoverOpportunity:industry:3]">
          </td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer" (click)="discoverOpportunity('industry', 3)"
            casiClickLogger="img[discoverOpportunity:industry:3]">Aquaculture</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/livestock-e.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer" (click)="discoverOpportunity('industry', 8)"
              casiClickLogger="img[discoverOpportunity:industry:8]">
          </td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer" (click)="discoverOpportunity('industry', 8)"
            casiClickLogger="img[discoverOpportunity:industry:8]">Livestock Extensive</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/livestock.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer" (click)="discoverOpportunity('industry', 5)"
              casiClickLogger="img[discoverOpportunity:industry:5]">
          </td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer" (click)="discoverOpportunity('industry', 5)"
            casiClickLogger="img[discoverOpportunity:industry:5]">Livestock Intensive</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/horticulture.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer" (click)="discoverOpportunity('industry', 10)"
              casiClickLogger="img[discoverOpportunity:industry:10]">
          </td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer" (click)="discoverOpportunity('industry', 10)"
            casiClickLogger="img[discoverOpportunity:industry:10]">Horticulture</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/broadacre.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer" (click)="discoverOpportunity('industry', 9)"
              casiClickLogger="img[discoverOpportunity:industry:9]">
          </td>
          <td><a style="cursor: pointer" (click)="discoverOpportunity('industry', 9)"
          casiClickLogger="img[discoverOpportunity:industry:9]">Cropping</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<div class="d-block d-lg-none">
  <div class="row row-nomargin" style="background-color: #efefef;">

    <div class="col-12" style="padding: 25px 25px 0px 25px;">
      <img src="assets/images/outcomes.png" style="max-width: 120px;cursor: pointer"
        (click)="discoverOpportunity('opportunity_goal_type', null)"
        casiClickLogger="img[discoverOpportunity:opportunity_goal_type:null]">
      <h2 class="h2s" style="font-weight: 500 !important; font-size: 20pt !important;">
        <a style="cursor: pointer; margin-right: 10px;" (click)="discoverOpportunity('opportunity_goal_type', null)"
        casiClickLogger="img[discoverOpportunity:opportunity_goal_type:null]">Project Goals</a> <casi-help-popover [infoId]="10"
          [iconStyle]="'color: #5d654d;'" style="display: inline-block;"></casi-help-popover></h2>

      <table>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/carbon.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer"
              (click)="discoverOpportunity('opportunity_goal_type', 1)"
              casiClickLogger="img[discoverOpportunity:opportunity_goal_type:1]"></td>
          <td style="border-bottom: 1px solid #cbc8c8; width: 86%;">
            <a style="cursor: pointer;" (click)="discoverOpportunity('opportunity_goal_type', 1)"
            casiClickLogger="img[discoverOpportunity:opportunity_goal_type:1]">Carbon and Emissions Reduction</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/biodiversity.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer"
              (click)="discoverOpportunity('opportunity_goal_type', 2)"
              casiClickLogger="img[discoverOpportunity:opportunity_goal_type:2]"></td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer;" (click)="discoverOpportunity('opportunity_goal_type', 2)"
            casiClickLogger="img[discoverOpportunity:opportunity_goal_type:2]">Natural Capital, Biodiversity and Ecosystem Services</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/sustainability.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer"
              (click)="discoverOpportunity('opportunity_goal_type', 3)"
              casiClickLogger="img[discoverOpportunity:opportunity_goal_type:3]"></td>
          <td><a style="cursor: pointer;" (click)="discoverOpportunity('opportunity_goal_type', 3)"
          casiClickLogger="img[discoverOpportunity:opportunity_goal_type:3]">Sustainability</a>
          </td>
        </tr>
      </table>
      <br>
    </div>

    <div class="col-12" style="padding: 25px 25px 0px 25px; border-top: 1px solid #cbc8c8;">
      <img src="assets/images/opportunities.png" style="max-width: 120px; cursor: pointer"
        (click)="discoverOpportunity('classification_type', null)"
        casiClickLogger="img[discoverOpportunity:classification_type:null]">
      <h2 class="h2s" style="font-weight: 500 !important; font-size: 20pt !important;">
        <a style="cursor: pointer; margin-right: 10px;" (click)="discoverOpportunity('classification_type', null)"
        casiClickLogger="img[discoverOpportunity:classification_type:null]">Market Opportunities</a> <casi-help-popover [infoId]="11"
          [iconStyle]="'color: #7ba89e;'" style="display: inline-block;"></casi-help-popover></h2>

      <table>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/income.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer"
              (click)="discoverOpportunity('classification_type', 1)"
              casiClickLogger="img[discoverOpportunity:classification_type:1]"></td>
          <td style="border-bottom: 1px solid #cbc8c8; width: 86%;">
            <a style="cursor: pointer;" (click)="discoverOpportunity('classification_type', 1)"
            casiClickLogger="img[discoverOpportunity:classification_type:1]">Financial Outcome</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/market.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer"
              (click)="discoverOpportunity('classification_type', 2)"
              casiClickLogger="img[discoverOpportunity:classification_type:2]"></td>
          <td><a style="cursor: pointer;" (click)="discoverOpportunity('classification_type', 2)"
          casiClickLogger="img[discoverOpportunity:classification_type:2]">Market Access</a>
          </td>
        </tr>
      </table>
      <br>
    </div>

    <div class="col-12" style="padding: 25px 25px 0px 25px; border-top: 1px solid #cbc8c8;">
      <img src="assets/images/benefits.png" style="max-width: 120px; cursor: pointer"
        (click)="discoverOpportunity('co_benefit_class', null)"
        casiClickLogger="img[discoverOpportunity:co_benefit_class:null]">
      <h2 class="h2s" style="font-weight: 500 !important; font-size: 20pt !important;">
        <a style="cursor: pointer; margin-right: 10px;" (click)="discoverOpportunity('co_benefit_class', null)"
        casiClickLogger="img[discoverOpportunity:co_benefit_class:null]">Project Outcomes</a> <casi-help-popover [infoId]="12"
          [iconStyle]="'color: #dd7f45;'" style="display: inline-block;"></casi-help-popover></h2>

      <table>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/production.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer"
              (click)="discoverOpportunity('co_benefit_class', 3)"
              casiClickLogger="img[discoverOpportunity:co_benefit_class:3]"></td>
          <td style="border-bottom: 1px solid #cbc8c8; width: 86%;">
            <a style="cursor: pointer;" (click)="discoverOpportunity('co_benefit_class', 3)"
            casiClickLogger="img[discoverOpportunity:co_benefit_class:3]">Production & Productivity</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/environment.png"
              style="max-width: 70px; min-width: 45px;cursor: pointer"
              (click)="discoverOpportunity('co_benefit_class', 1)"
              casiClickLogger="img[discoverOpportunity:co_benefit_class:1]"></td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer;" (click)="discoverOpportunity('co_benefit_class', 1)"
            casiClickLogger="img[discoverOpportunity:co_benefit_class:1]">Environment</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/financial.png"
              style="max-width: 70px; min-width: 45px;cursor: pointer"
              (click)="discoverOpportunity('co_benefit_class', 2)"
              casiClickLogger="img[discoverOpportunity:co_benefit_class:2]"></td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer;" (click)="discoverOpportunity('co_benefit_class', 2)"
            casiClickLogger="img[discoverOpportunity:co_benefit_class:2]">Financial</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/supply.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer"
              (click)="discoverOpportunity('co_benefit_class', 5)"
              casiClickLogger="img[discoverOpportunity:co_benefit_class:5]"></td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer;" (click)="discoverOpportunity('co_benefit_class', 5)"
            casiClickLogger="img[discoverOpportunity:co_benefit_class:5]">Supply Chain</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/social.png"
              style="max-width: 70px; min-width: 45px;cursor: pointer"
              (click)="discoverOpportunity('co_benefit_class', 4)"
              casiClickLogger="img[discoverOpportunity:co_benefit_class:4]"></td>
          <td>
            <a style="cursor: pointer;" (click)="discoverOpportunity('co_benefit_class', 4)"
            casiClickLogger="img[discoverOpportunity:co_benefit_class:4]">Social</a>
          </td>
        </tr>
      </table>
      <br>
    </div>

    <div class="col-12" style="padding: 25px 25px 0px 25px; border-top: 1px solid #cbc8c8;">
      <img src="assets/images/industries.png" style="max-width: 120px; cursor: pointer"
        (click)="discoverOpportunity('industry', null)" casiClickLogger="img[discoverOpportunity:industry:null]">
      <h2 class="h2s" style="font-weight: 500 !important; font-size: 20pt !important;">
        <a style="cursor: pointer; margin-right: 10px;" (click)="discoverOpportunity('industry', null)" casiClickLogger="img[discoverOpportunity:industry:null]">Industries</a> <casi-help-popover [infoId]="13"
          [iconStyle]="'color: #d9b35b;'" style="display: inline-block;"></casi-help-popover></h2>

      <table>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/forestry-n.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer" (click)="discoverOpportunity('industry', 2)"
              casiClickLogger="img[discoverOpportunity:industry:2]">
          </td>
          <td style="border-bottom: 1px solid #cbc8c8; width: 86%;">
            <a style="cursor: pointer;" (click)="discoverOpportunity('industry', 2)"
            casiClickLogger="img[discoverOpportunity:industry:2]">Forestry Native</a>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/forestry2.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer" (click)="discoverOpportunity('industry', 1)"
              casiClickLogger="img[discoverOpportunity:industry:1]">
          </td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer;" (click)="discoverOpportunity('industry', 1)"
            casiClickLogger="img[discoverOpportunity:industry:1]">Forestry Plantation</a>
          </td>

        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/fisheries-w.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer" (click)="discoverOpportunity('industry', 4)"
              casiClickLogger="img[discoverOpportunity:industry:4]">
          </td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer;" (click)="discoverOpportunity('industry', 4)"
            casiClickLogger="img[discoverOpportunity:industry:4]">Fishing</a>
          </td>

        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/fisheries-i.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer" (click)="discoverOpportunity('industry', 3)"
              casiClickLogger="img[discoverOpportunity:industry:3]">
          </td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer;" (click)="discoverOpportunity('industry', 3)"
            casiClickLogger="img[discoverOpportunity:industry:3]">Aquaculture</a>
          </td>

        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/livestock-e.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer" (click)="discoverOpportunity('industry', 8)"
              casiClickLogger="img[discoverOpportunity:industry:8]">
          </td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer;" (click)="discoverOpportunity('industry', 8)"
            casiClickLogger="img[discoverOpportunity:industry:8]">Livestock Extensive</a>
          </td>

        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/livestock.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer" (click)="discoverOpportunity('industry', 5)"
              casiClickLogger="img[discoverOpportunity:industry:5]">
          </td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer;" (click)="discoverOpportunity('industry', 5)"
            casiClickLogger="img[discoverOpportunity:industry:5]">Livestock Intensive</a>
          </td>

        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/horticulture.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer" (click)="discoverOpportunity('industry', 10)"
              casiClickLogger="img[discoverOpportunity:industry:10]">
          </td>
          <td style="border-bottom: 1px solid #cbc8c8;">
            <a style="cursor: pointer;" (click)="discoverOpportunity('industry', 10)"
            casiClickLogger="img[discoverOpportunity:industry:10]">Horticulture</a>
          </td>

        </tr>
        <tr>
          <td style="padding-right: 20px; padding-bottom: 5px;"><img src="assets/images/broadacre.png"
              style="max-width: 70px; min-width: 45px; cursor: pointer" (click)="discoverOpportunity('industry', 9)"
              casiClickLogger="img[discoverOpportunity:industry:9]">
          </td>
          <td>
            <a style="cursor: pointer;" (click)="discoverOpportunity('industry', 9)"
            casiClickLogger="img[discoverOpportunity:industry:9]">Cropping</a>
          </td>

        </tr>
      </table>
      <br>
    </div>

  </div>
</div>
