<div style="text-align: center; padding: 160px 20px 110px 20px;">
  <h2 style="font-weight: 500 !important;">Relevant for all primary industries</h2>
  <hr style="width: 50px; border-top: 5px solid #dd7f45; margin: 40px auto;">

  <div class="d-none d-lg-block" style="max-width: 1200px; margin: auto;">
    <div class="row row-nomargin" style="margin-top: 60px; margin-bottom: 60px;">
      <div class="col-lg-3" style="text-align: center; border-right: 1px solid #cbc8c8;">
        <a style="cursor: pointer" (click)="discoverOpportunity('industry', [1, 2])" casiClickLogger="img[discoverOpportunity:industry:[1, 2]]">
        <img src="assets/images/forestry.png"
          style="margin-bottom: 20px; width: 100%; padding: 0px 40px 10px 40px;">
        <h2 style="font-weight: 500 !important;">Forestry</h2>
        </a>
      </div>
      <div class="col-lg-3" style="text-align: center; border-right: 1px solid  #cbc8c8;">
        <a style="cursor: pointer" (click)="discoverOpportunity('industry', [3, 4])" casiClickLogger="img[discoverOpportunity:industry:[3, 4]]">
        <img src="assets/images/fishers.png"
          style="margin-bottom: 20px; width: 100%; padding: 0px 40px 10px 40px;">
        <h2 style="font-weight: 500 !important;">Fisheries</h2>
        </a>
      </div>
      <div class="col-lg-3" style="text-align: center; border-right: 1px solid  #cbc8c8;">
        <a style="cursor: pointer" (click)="discoverOpportunity('industry', [5, 8])" casiClickLogger="img[discoverOpportunity:industry:[5, 8]]">
        <img src="assets/images/farming.png"
          style="margin-bottom: 20px; width: 100%; padding: 0px 40px 10px 40px;">
        <h2 style="font-weight: 500 !important;">Livestock</h2>
        </a>
      </div>
      <div class="col-lg-3" style="text-align: center;">
        <a style="cursor: pointer" (click)="discoverOpportunity('industry', [9, 10])"
        casiClickLogger="img[discoverOpportunity:industry:[9, 10]]">
        <img src="assets/images/cropping.png"
          style="margin-bottom: 20px; width: 100%; padding: 0px 40px 10px 40px;">
        <h2 style="font-weight: 500 !important;">Cropping</h2>
        </a>
      </div>
    </div>
  </div>

  <div class="d-block d-lg-none">
    <div class="row row-nomargin" style="margin-top: 20px; margin-bottom: 20px;">
      <div class="col-12 col-md-6" style="text-align: center;">
        <a style="cursor: pointer" (click)="discoverOpportunity('industry', [1, 2])" casiClickLogger="img[discoverOpportunity:industry:[1, 2]]">
        <img src="assets/images/forestry.png" style="margin-bottom: 20px; max-width: 40%;">
        <h2 style="font-weight: 500 !important;">Forestry</h2>
        </a>
        <br>
      </div>
      <div class="col-12 col-md-6" style="text-align: center;">
        <a style="cursor: pointer" (click)="discoverOpportunity('industry', [3, 4])" casiClickLogger="img[discoverOpportunity:industry:[3, 4]]">
        <img src="assets/images/fishers.png" style="margin-bottom: 20px; max-width: 40%;">
        <h2 style="font-weight: 500 !important;">Fisheries</h2>
        </a>
        <br>
      </div>
      <div class="col-12 col-md-6" style="text-align: center;">
        <a style="cursor: pointer" (click)="discoverOpportunity('industry', [5, 8])" casiClickLogger="img[discoverOpportunity:industry:[5, 8]]">
        <img src="assets/images/farming.png" style="margin-bottom: 20px; max-width: 40%; cursor: pointer">
        <h2 style="font-weight: 500 !important;">Livestock</h2>
        </a>
        <br>
      </div>
      <div class="col-12 col-md-6" style="text-align: center;">
        <a style="cursor: pointer" (click)="discoverOpportunity('industry', [9, 10])"
        casiClickLogger="img[discoverOpportunity:industry:[9, 10]]">
        <img src="assets/images/cropping.png" style="margin-bottom: 20px; max-width: 40%;">
        <h2 style="font-weight: 500 !important;">Cropping</h2>
        </a>
        <br>
      </div>
    </div>
  </div>


</div>
