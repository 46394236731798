<div class="row row-nomargin" style="background-color: #efefef; padding: 40px 60px 120px 60px;">
  <hr style="width: 50px; border-top: 5px solid #dd7f45; margin: 40px auto 50px auto;">
  <div class="col-12">
    <h2 style="line-height: 1.3; margin: auto; text-align: center; font-weight: 500 !important; max-width: 85%;">
      The University of New England in partnership with AgriFutures Australia, developed this free
      @if(isLoggedIn) {<a routerLinkActive="active" routerLink="/discover-opportunities"
      casiClickLogger="a[discoverOpportunity]">online decision-support tool</a>} @else {online decision-support tool}
      for farmers, fishers and foresters who want to learn more about managing natural
      capital, biodiversity and carbon for multiple benefits. The focus is on opportunities that provide access to
      commodity markets and financial products and outcomes related to carbon and emissions reduction, sustainability
      and environmental stewardship.
      <!-- <span><a routerLinkActive="active" routerLink="/discover-opportunities"
          casiClickLogger="a[discoverOpportunity]">
          Search Australia’s knowledge hub</a> bringing credibility to assess opportunities in
        carbon, biodiversity, natural capital,
        ecosystem services and sustainability </span> -->
    </h2>
    <!-- <h2 style="line-height: 1.3; margin: auto; text-align: center; font-weight: 500 !important; max-width: 85%;">
      The University of New England in partnership with AgriFutures Australia, developed this free
      @if(isLoggedIn) {<a routerLinkActive="active" routerLink="/discover-opportunities"
      casiClickLogger="a[discoverOpportunity]">online decision support tool</a>} @else {online decision support tool}
      <a routerLinkActive="active" routerLink="/discover-opportunities"
          casiClickLogger="a[discoverOpportunity]">online decision support tool</a> for farmers, fishers and foresters who want to learn more about managing natural
      capital, biodiversity, and carbon for multiple benefits. The focus is on opportunities that provide access to
      commodity markets and financial products and outcomes related to carbon and emission reduction, sustainability,
      and environmental stewardship.
    </h2> -->
 </div>
</div>
